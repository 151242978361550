import React from "react"
import { Link } from "gatsby"
import Play from "../components/play"

const softCtaSection = () => (
    <div className="flex flex-col gap-[10px] items-center">
        <div className="flex items-center gap-[32px]">
            <div>
                <Link to="/play-interest"
                    className="py-[12px] px-[16px] text-white bg-[#DB28A9] rounded-[8px] font-semibold font-general-sans-semibold"
                >
                    Ping me when we launch
                </Link>
            </div>
        </div>
        <p className="text-[#475467] text-[16px] italic mt-[12px]">2 weeks until release</p>
    </div>
)

const PlayV2Page = () => {
    return (
        <Play ctaSection={softCtaSection()} />
    )
}

export default PlayV2Page
